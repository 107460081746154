import React from 'react';
import Layout from '../components/layout/layout';
import PageHeader from '../components/paragraphs/page-header/page-header';
import SEO from '../components/seo';
import * as he from 'he';
import { graphql, Link } from 'gatsby';
import Container from '../components/container/container';
import './case.scss';
import { parseDrupalURI } from '../helpers/helpers';
import ReactHtmlParser from 'react-html-parser';

export default function Case({ data, location, pageContext }) {
  const { langCode, nid } = pageContext;
  const cta = data.nodeCase.relationships.field_call_to_action;
  let body = new ReactHtmlParser(data.nodeCase.body.processed, {
    transform: function transform(node) {
      if (node.type === 'tag' && node.name === 'img') {
        let className = node.attribs['class'];
        let alt = node.attribs['alt'];
        let src = node.attribs['src'];
        return (
          <img
            className={[className].join(' ')}
            loading="lazy"
            src={'https://cms.djar.fit' + src}
            alt={alt}
          />
        );
      }

      // if (node.type === 'script' && node.name === 'script') {
      //   let src = node.attribs['src'];
      // }
      return undefined;
    }
  });
  return (
    <Layout location={location} lang={langCode} nid={nid}>
      <SEO
        title={he.decode(data.nodeCase.title)}
        description={
          data.nodeCase.body && data.nodeCase.body.summary
            ? data.nodeCase.body.summary
            : null
        }
        lang={langCode}
        nid={nid}
      />
      <PageHeader
        align={'Links'}
        colors={'Groen'}
        title={data.nodeCase.title}
        breadcrumbs={[
          { url: '/', title: 'Home' },
          { url: '/cases', title: 'Cases' }
        ]}
        langCode={langCode}
      ></PageHeader>
      <div className="case-wrapper">
        <Container>
          <div className="content">
            <article>{body}</article>
            <aside>
              {cta && (
                <div
                  className={`${cta.relationships.field_color_scheme.name.toLowerCase()} ${cta.relationships.field_alignment.name.toLowerCase()} cta`}
                >
                  {cta.field_title && <h3>{cta.field_title}</h3>}
                  {cta.field_text && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: cta.field_text.replace(
                          'src="',
                          'src="https://cms.djar.fit'
                        )
                      }}
                    ></div>
                  )}
                  {cta.field_cta.map((button, index) => {
                    if (
                      parseDrupalURI(button.uri).charAt(0) === '/' ||
                      parseDrupalURI(button.uri).charAt(0) === '#'
                    ) {
                      return (
                        <div className="cta__item" key={index}>
                          <Link
                            to={parseDrupalURI(button.uri, langCode)}
                            className={[
                              'btn big',
                              index % 2 === 0
                                ? ''
                                : cta.relationships.field_color_scheme.name ===
                                  'Groen'
                                ? 'secondary dark'
                                : 'secondary',
                              cta.relationships.field_alignment.name
                            ].join(' ')}
                          >
                            {button.title}
                          </Link>
                        </div>
                      );
                    } else {
                      return (
                        <div className="cta__item" key={index}>
                          <a
                            href={parseDrupalURI(button.uri, langCode)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={[
                              'btn big',
                              index % 2 === 0
                                ? ''
                                : cta.relationships.field_color_scheme.name ===
                                  'Groen'
                                ? 'secondary dark'
                                : 'secondary',
                              cta.relationships.field_alignment.name
                            ].join(' ')}
                          >
                            {button.title}
                          </a>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </aside>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    allNodeCustomer(sort: { fields: [created], order: DESC }) {
      nodes {
        created
        title
        field_quote
        field_location
        field_website {
          uri
        }
        field_logo {
          imageDerivatives {
            links {
              icon_landscape {
                href
              }
            }
          }
        }
      }
    }

    nodeCase(path: { alias: { eq: $slug } }, status: { eq: true }) {
      title
      status
      body {
        processed
        summary
      }
      path {
        alias
      }
      relationships {
        field_media {
          field_media_image {
            imageDerivatives {
              links {
                blog_overview {
                  href
                }
                blog_overview_webp {
                  href
                }
              }
            }
          }
        }
        field_call_to_action {
          field_cta {
            title
            uri
          }
          field_title
          field_text
          relationships {
            field_color_scheme {
              name
            }
            field_alignment {
              name
            }
          }
        }
      }
    }
  }
`;
